<!-- 详情弹窗 -->
<template>
  <a-modal
    title="详情"
    :width="580"
    :footer="null"
    :visible="visible"
    @update:visible="updateVisible"
  >
    <a-form
      class="ud-form-detail"
      :label-col="{ sm: { span: 5 }, xs: { span: 6 } }"
      :wrapper-col="{ sm: { span: 19 }, xs: { span: 18 } }"
    >
      <a-row :gutter="16">
        <a-col :sm="24" :xs="24">
          <!-- 优惠券名称 -->
          <a-form-item label="优惠券名称:">
            <div class="ud-text-secondary">
              {{ data.name }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 最低消费 -->
          <a-form-item label="最低消费:">
            <div class="ud-text-secondary">
              满{{ data.minPrice }}元
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 优惠券类型 -->
          <a-form-item label="优惠券类型:">
            <div class="ud-text-secondary" v-if="data.couponType === 10">
              满减券
            </div>
            <div class="ud-text-secondary" v-if="data.couponType === 20">
              折扣券
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24" v-if="data.couponType === 10">
          <!-- 减免金额 -->
          <a-form-item label="减免金额:">
            <div class="ud-text-secondary">
              {{ data.reducePrice }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24" v-if="data.couponType === 20">
          <!-- 折扣率 -->
          <a-form-item label="折扣率:">
            <div class="ud-text-secondary">
              {{ data.discount }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 到期类型 -->
          <a-form-item label="到期类型:">
            <div class="ud-text-secondary" v-if="data.expireType === 10">
             领取后生效
            </div>
            <div class="ud-text-secondary" v-if="data.expireType === 20">
              固定时间
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24" v-if="data.expireType === 10">
          <!-- 有效天数 -->
          <a-form-item label="有效天数:">
            <div class="ud-text-secondary">
              {{ data.expireDay }}天
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24" v-if="data.expireType === 20">
          <!-- 固定时间 -->
          <a-form-item label="固定时间:">
            <div class="ud-text-secondary">
              {{ data.couponTime }}
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 适用范围 -->
          <a-form-item label="适用范围:">
            <div class="ud-text-secondary" v-if="data.applyRange === 10">
             全部商品
            </div>
            <div class="ud-text-secondary" v-if="data.applyRange === 20">
              指定商品
            </div>
          </a-form-item>
        </a-col>
        <a-col :sm="24" :xs="24">
          <!-- 状态 -->
          <a-form-item label="状态:">
            <div class="ud-text-secondary"  >
              {{ data.statusName }}
            </div>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
export default {
  name: 'cardDetail',
  emits: ['update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 数据
    data: Object
  },
  data() {
    return {}
  },
  watch: {
    data() {
      if (this.data) {
        // console.log('data111', this.data)
        // this.data.map((item) => {
        //   if (item.status === 0) {
        //     item.statusName = '禁用'
        //   } else if (item.status === 1) {
        //     item.statusName = '启用'
        //   }
        // })
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      // this.data.map((item) => {
      //   if (item.status === 0) {
      //     item.statusName = '禁用'
      //   } else if (item.status === 1) {
      //     item.statusName = '启用'
      //   }
      // })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>

<style scoped>
.answer {
  padding-left: 5%;
}
</style>
